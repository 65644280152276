import React from "react";
import PropTypes from "prop-types";

import imgOVH from "../../images/partners/ovh.svg";
import imgBlueMedia from "../../images/partners/blue-media.svg";

const Partner = ({ t, image }) => (
  <div className="Partners__item">
    <img className="Partners__item__image" src={image} alt={t.name} />
    <p>{t.name}</p>
    <p className="Partners__item__description">{t.description}</p>
  </div>
);

Partner.propTypes = {
  t: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired
};

const Partners = ({ t }) => (
  <section className="section section--gray Partners">
    <div className="container">
      <h2 className="title">{t.title}</h2>

      <div className="Partners__list">
        <Partner t={t.ovh} image={imgOVH} />
        <Partner t={t.blueMedia} image={imgBlueMedia} />
      </div>
    </div>
  </section>
);

Partners.propTypes = {
  t: PropTypes.object.isRequired
};

export default Partners;
