import React from "react";
import PropTypes from "prop-types";
import SocialMedia from "../common/SocialMedia";

import imgGetinweb from "../../images/common/getinweb.svg";

const Home = ({ t }) => (
  <div className="Home__hero">
    <div className="container Home__hero__container">
      <div className="Home__hero__content">
        <img className="Home__hero__logo" src={imgGetinweb} alt="Getinweb" />

        <h1 className="Home__hero__title text-animate text-animate--infinite">{t.title}</h1>

        <div className="Home__hero__keywords">
          {t.keywords.map((elem, i) => (
            <h2 key={i}>{elem}</h2>
          ))}
        </div>
      </div>

      <div className="Home__hero__socialMedia">
        <SocialMedia />
      </div>
    </div>
  </div>
);

Home.propTypes = {
  t: PropTypes.object.isRequired
};

export default Home;
