import React from "react";
import PropTypes from "prop-types";
import Layout from "../../layouts/Layout";
import Seo from "../seo";
import Hero from "./Hero";
import ServicesSection from "../common/ServicesSection";
// import News from "../common/News";
// import Reviews from "../common/Reviews";
import Partners from "../common/Partners";
import { getShareImage } from "../../utils/getShareImage";

// import imgGetinweb from "../../images/common/getinweb.svg";
// import imgBasicImage from "../../images/common/basic-image.svg";

const Home = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <div className="Home">
        <Hero t={t.hero} />

        <ServicesSection t={t.services} lang={lang} servicesT={servicesT} />

        {/* <News t={t.news} lang={lang} images={[imgBasicImage, imgBasicImage, imgBasicImage]} /> */}

        {/* <Reviews t={t.reviews} logos={[imgGetinweb, imgGetinweb, imgGetinweb, imgGetinweb, imgGetinweb, imgGetinweb]} /> */}

        <Partners t={t.partners} />
      </div>
    </Layout>
  );
};

Home.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Home;
