import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { getHrefs } from "../../utils/langService";
import LinkButton from "./LinkButton";

import imgDesktop from "../../images/common/desktop.svg";
import imgCloud from "../../images/common/cloud.svg";
import imgMarketing from "../../images/common/marketing.svg";
import imgContent from "../../images/common/content.svg";

const ListItem = ({ name, to }) => (
  <li>
    <Link to={to}>{name}</Link>
  </li>
);

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

const ServicesSection = ({ t, lang, servicesT }) => {
  const { pages, services } = getHrefs(lang);

  return (
    <section className="section section--gray ServicesSection">
      <div className="container ServicesSection__container">
        <div className="ServicesSection__left">
          <h2 className="title">{t.title}</h2>
          <p className="ServicesSection__left__description">{t.description}</p>
          <LinkButton to={`/${lang}/${pages[3]}/`}>{t.btn}</LinkButton>
        </div>

        <div className="ServicesSection__list">
          <div className="ServicesSection__item">
            <div className="ServicesSection__item__image">
              <img src={imgDesktop} alt={servicesT.software.title} />
            </div>
            <h3 className="ServicesSection__item__title">{servicesT.software.title}</h3>

            <ul className="list-circle list-circle--primary">
              <ListItem name={servicesT.software.website} to={`/${lang}/${services.software.name}/${services.software.pages[0]}/`} />
              <ListItem name={servicesT.software.webshop} to={`/${lang}/${services.software.name}/${services.software.pages[1]}/`} />
              <ListItem name={servicesT.software.blog} to={`/${lang}/${services.software.name}/${services.software.pages[2]}/`} />
              <ListItem name={servicesT.software.webApp} to={`/${lang}/${services.software.name}/${services.software.pages[3]}/`} />
              <ListItem name={servicesT.software.desktopApp} to={`/${lang}/${services.software.name}/${services.software.pages[4]}/`} />
              <ListItem name={servicesT.software.mobileApp} to={`/${lang}/${services.software.name}/${services.software.pages[5]}/`} />
              <ListItem name={servicesT.software.support} to={`/${lang}/${services.software.name}/${services.software.pages[6]}/`} />
            </ul>
          </div>

          <div className="ServicesSection__item">
            <div className="ServicesSection__item__image">
              <img src={imgCloud} alt={servicesT.cloud.title} />
            </div>
            <h3 className="ServicesSection__item__title">{servicesT.cloud.title}</h3>

            <ul className="list-circle list-circle--primary">
              {/* <ListItem name={servicesT.cloud.domain} to={`/${lang}/`} /> */}
              <ListItem name={servicesT.cloud.hosting} to={`/${lang}/${services.cloud.name}/${services.cloud.pages[0]}/`} />
              <ListItem name={servicesT.cloud.vps} to={`/${lang}/${services.cloud.name}/${services.cloud.pages[1]}/`} />
              {/* <ListItem name={servicesT.cloud.server} to={`/${lang}/`} /> */}
              {/* <ListItem name={servicesT.cloud.ssl} to={`/${lang}/`} /> */}
              <ListItem name={servicesT.cloud.email} to={`/${lang}/${services.cloud.name}/${services.cloud.pages[2]}/`} />
            </ul>
          </div>

          <div className="ServicesSection__item">
            <div className="ServicesSection__item__image">
              <img src={imgMarketing} alt={servicesT.marketing.title} />
            </div>
            <h3 className="ServicesSection__item__title">{servicesT.marketing.title}</h3>

            <ul className="list-circle list-circle--primary">
              <ListItem name={servicesT.marketing.websitePositioning} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[0]}/`} />
              <ListItem name={servicesT.marketing.seo} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[1]}/`} />
              <ListItem name={servicesT.marketing.advertisement} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[2]}/`} />
              <ListItem name={servicesT.marketing.copywriting} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[3]}/`} />
            </ul>
          </div>

          <div className="ServicesSection__item">
            <div className="ServicesSection__item__image">
              <img src={imgContent} alt={servicesT.additional.title} />
            </div>
            <h3 className="ServicesSection__item__title">{servicesT.additional.title}</h3>

            <ul className="list-circle list-circle--primary">
              <ListItem name={servicesT.additional.translations} to={`/${lang}/${services.additional.name}/${services.additional.pages[0]}/`} />
              <ListItem name={servicesT.additional.law} to={`/${lang}/${services.additional.name}/${services.additional.pages[1]}/`} />
              {/* <ListItem name={servicesT.additional.office365} to={`/${lang}/`} /> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

ServicesSection.propTypes = {
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  servicesT: PropTypes.object.isRequired
};

export default ServicesSection;
